import { ENTITY_TYPES } from "utils/constants";
import Tweet from "components/TweetCard/Tweet";
import UserCell from "../../EntityExplorer/components/UserCell";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const styles = {
	postWrap: {
		backgroundColor: "#333146",
		borderRadius: "6px",
	},
	entityWrap: {
		marginBottom: "4px",
	},
	url: {
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
};

export default function ExplanationEntity({ type, entity }) {
	return (
		<Box sx={styles.entityWrap}>
			{(type === ENTITY_TYPES.post || type === ENTITY_TYPES.cluster) && (
				<Box sx={styles.postWrap}>
					<Tweet {...entity} />
				</Box>
			)}
			{type === ENTITY_TYPES.user && <UserCell {...entity} />}

			{type === ENTITY_TYPES.url && (
				<Typography variant={"body2"} sx={styles.url} noWrap>
					<Link href={entity.id} target="_blank">
						{entity.id}
					</Link>
				</Typography>
			)}

			{type === ENTITY_TYPES.hashtag && (
				<Typography variant={"h6"}>#{entity.id}</Typography>
			)}
		</Box>
	);
}
