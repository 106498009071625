import { useEffect, useMemo, useState } from "react";
import { Box, Collapse, Grid, IconButton, useTheme } from "@mui/material";
import SummaryItem from "./summaryItem";
import {
  totalPosts,
  totalEngagements,
  totalUsers,
  emotions,
  sentiments,
  totalUsersReturnField,
  defaultReturnFields,
  postHierarchy,
} from "utils/summaryReturnFields";
import { loadSummary, loadSummaryUserGroups } from "api/endpoints/AnalyticsApi";
import { getSummaries } from "./summaryConfig";
import { useAppContext } from "context/Context";
import useApi from "api/hooks/useApi";
import { QUERY_KEYS, ROUTE_PATHES } from "utils/constants";
import useEventTracker from "api/hooks/useEventTracker";
import useTabs from "hooks/useTabs";
import { useParams } from "react-router-dom";
import { mergeQueries } from "../../EntityExplorer/utils/EntityExplorerUtils";
import Details from "./Details";
import { ReactComponent as DownArrow } from "icons/UI/stats-down-arrow.svg";
import { ReactComponent as UpArrow } from "icons/UI/stats-up-arrow.svg";

const MIN_WIDTH = "150px";
const MAX_WIDTH = "400px";

const useStyles = ({ theme, ...props }) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.$0,
    overflow: "auto",
    display: "flex",
    borderBottom: props.open ? "1px solid " + theme.palette.border.main : null,
  },
  content: {
    flexGrow: 1,
  },
  item: {
    flex: "1 1 0px",
    maxWidth: MAX_WIDTH,
    minWidth: MIN_WIDTH,
    height: "100%",
    padding: theme.spacing(0),
  },
  summaryItems: {
    width: "100%",
    alignItems: "flex-start",
    display: "flex",
    borderBottom: "1px solid " + theme.palette.border.main,
    paddingLeft: theme.spacing(1),
  },
  row: {
    minWidth: "30%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: theme.palette.widget.main,
    height: "75px",
    flexGrow: 1,
  },
  toggler: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "76px",
    paddingRight: theme.spacing(3),
    borderBottom: "1px solid " + theme.palette.border.main,
  },
  togglerBtn: {
    width: "28px",
    height: "28px",
    borderRadius: "4px",
    padding: "2px",
    border: "1px solid " + theme.palette.border.main,
  },
  details: {
    width: "100%",
    height: "500px",
    overflowY: "auto",
    alignItems: "flex-start",
    display: "flex",
    paddingLeft: theme.spacing(1),
  },
  detail: {
    flex: "1 1 0px",
    minWidth: MIN_WIDTH,
    maxWidth: MAX_WIDTH,
    borderRight: "1px solid " + theme.palette.border.main,
    height: "100%",
    padding: theme.spacing(1, 0),
    "&:last-child": {
      "& .detail_row": {
        paddingRight: 0,
      },
      borderRight: "none",
    },
  },
});

export default function Summary() {
  const [detailsOn, setDetailsOn] = useState(false);
  const [details, setDetails] = useState({});
  const tracker = useEventTracker();
  const { currentPath } = useTabs();
  const { projectName } = useParams();

  const {
    state: {
      analyzeSearch,
      narrative,
      start_date,
      end_date,
      platform,
      harmProfile,
      harmClassifier,
      userGroups,
      cohortList: topics,
      userCohorts,
      dataLoading
    },
  } = useAppContext();

  const analyzeFiltersObj = useMemo(
    () =>
      narrative.analyze_filters ? narrative.analyze_filters.serialized : {},
    [narrative.analyze_filters]
  );

  const summaryDataMemo = useMemo(() => {
    const analyzeFilters = narrative.analyze_filters?.serialized || {};

    const filters = mergeQueries(analyzeSearch, analyzeFilters);

    return {
      db: projectName,
      req: {
        start_date,
        end_date,
        platform,
        latestKeywords: narrative?.keywords,
        ...filters,
        topic: narrative?.name,
        harmProfile,
        harmClassifier,
        returnFields: [...defaultReturnFields, ...emotions, ...sentiments],
      },
    };
  }, [
    analyzeSearch,
    start_date,
    end_date,
    platform,
    narrative.name,
    projectName,
    narrative.keywords,
    harmProfile,
    harmClassifier,
    narrative.analyze_filters?.serialized,
  ]);

  const { data: summaryResp, isLoading: summaryLoading } = useApi({
    apiKey: QUERY_KEYS.summary,
    payload: summaryDataMemo,
    apiFn: loadSummary,
    enabled: Boolean(
      !dataLoading &&
      !narrative.isDefault &&
        narrative.name &&
        platform &&
        (currentPath === ROUTE_PATHES.overview ||
          currentPath === ROUTE_PATHES.analyze)
    ),
  });

  const summaryData = useMemo(
    () => summaryResp && summaryResp[0],
    [summaryResp]
  );

  const detailedMemo = useMemo(() => {
    const allReturnFields = [
      totalPosts(platform),
      postHierarchy(platform),
      totalEngagements(platform),
      totalUsers(platform),
      emotions,
      sentiments,
    ];

    const filters = mergeQueries(analyzeSearch, analyzeFiltersObj);
    return {
      db: projectName,
      req: {
        // instead of Promise.all, what if we unite returnFields like this?
        returnFields: allReturnFields
          .flatMap((d) => d)
          .filter((d) => !d.usesDefaultResponse),
        latestKeywords: narrative.keywords,
        ...filters,
        topic: narrative.name,
        start_date,
        end_date,
        platform,
        harmProfile,
        harmClassifier,
      },
    };
  }, [
    harmProfile,
    harmClassifier,
    narrative.keywords,
    projectName,
    narrative.name,
    start_date,
    end_date,
    analyzeSearch,
    analyzeFiltersObj,
    platform,
  ]);

  const { data: allDetails, isLoading: loading } = useApi({
    apiKey: QUERY_KEYS.summary,
    apiFn: loadSummary,
    payload: detailedMemo,
    enabled: detailsOn && !dataLoading,
  });

  const userGroupNames = useMemo(() => {
    if (analyzeFiltersObj.user_group_names) {
      return analyzeFiltersObj.user_group_names;
    }
    return userGroups.map((d) => d.name);
  }, [userGroups, analyzeFiltersObj.user_group_names]);

  const detailsByUGMemo = useMemo(() => {
    const filters = mergeQueries(analyzeSearch, analyzeFiltersObj);

    return {
      db: projectName,
      requests: userGroupNames.map((group) => ({
        returnFields: totalUsersReturnField,
        latestKeywords: narrative.keywords,
        ...filters,
        topic: narrative.name,
        start_date,
        end_date,
        platform,
        harmProfile,
        harmClassifier,
        user_group_names: [group],
      })),
    };
  }, [
    platform,
    start_date,
    end_date,
    harmProfile,
    harmClassifier,
    projectName,
    narrative.name,
    narrative.keywords,
    analyzeSearch,
    analyzeFiltersObj,
    userGroupNames,
  ]);

  const { data: detailsByUG } = useApi({
    apiKey: QUERY_KEYS.summary + "user_groups",
    apiFn: loadSummaryUserGroups,
    payload: detailsByUGMemo,
    enabled: detailsOn && userGroupNames.length > 0 && !dataLoading,
  });

  const summaries = getSummaries({
    summaryData: summaryData || {},
    details,
    loading,
    platform,
    userGroups: userGroupNames,
    topics,
    userCohorts,
  });

  const theme = useTheme();
  const styles = useStyles({
    theme,
    open: detailsOn,
  });

  useEffect(() => {
    let newDetails = {};

    if (summaryData) {
      newDetails = { ...newDetails, ...summaryData };
    }

    if (detailsByUG) {
      newDetails = { ...newDetails, ...detailsByUG };
    }

    if (allDetails && detailedMemo.req) {
      newDetails = {
        ...newDetails,
        ...(allDetails[0] || {}),
      };
      detailedMemo.req.returnFields.forEach(({ field_name, return_name }) => {
        newDetails[field_name] = allDetails[0][return_name];
      });
    }

    setDetails(newDetails);
  }, [detailsByUG, allDetails, detailedMemo, summaryData]);

  useEffect(() => {
    setDetailsOn(false);
  }, [currentPath]);

  const toggleDetails = () => {
    tracker.track(
      `Expanded detailed stats [${detailsOn ? "hide" : "show"}]`,
      detailsOn ? "Collapse" : "Expand",
      detailsOn ? "Collapse" : "Expand",
      "Detailed stats widget"
    );
    setDetailsOn(!detailsOn);
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.content}>
        <Grid
          container
          alignItems="center"
          wrap="nowrap"
          sx={styles.summaryItems}
        >
          {summaries.map((s, i) => (
            <Grid item key={i} sx={styles.item}>
              <SummaryItem
                {...s}
                isLast={i === summaries.length - 1}
                loading={summaryLoading}
              />
            </Grid>
          ))}
        </Grid>

        <Collapse in={detailsOn}>
          <Box sx={styles.details}>
            {summaries.map(({ details = [] }, i) => (
              <Box key={i} sx={styles.detail}>
                {details.map((detail, i) => (
                  <Details key={i} {...detail} loading={loading} />
                ))}
              </Box>
            ))}
          </Box>
        </Collapse>
      </Box>
      <Box sx={styles.toggler}>
        <IconButton
          onClick={toggleDetails}
          sx={styles.togglerBtn}
          id="ShowDetailedStats"
        >
          {detailsOn ? <UpArrow /> : <DownArrow />}
        </IconButton>
      </Box>
    </Box>
  );
}
