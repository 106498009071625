import { ReactComponent as UserMaleIcon } from "icons/NarBuilder/user-male.svg";
import { ReactComponent as ClusterIcon } from "icons/NarBuilder/cluster.svg";
import { ReactComponent as CommentIcon } from "icons/UI/comment.svg";
import { Box, useTheme } from "@mui/material";

const useStyles = ({ theme }) => ({
  roundBox: {
    borderRadius: "4px",
    padding: "7px 10px 7px 15px",
    fontWeight: "700",
    fontSize: "13px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginRight: "10px",
    },
  },
  cluster: {
    backgroundColor: "rgba(236, 236, 236, 0.1)",
    color: "#ECECEC",
  },
  orig: {
    backgroundColor: "rgba(57, 243, 197, 0.1)",
    color: theme.palette.primary.main,
  },
  shared: {
    backgroundColor: "rgba(181, 192, 255, 0.1)",
    color: "#B5C0FF",
  },
  comment: {
    backgroundColor: "rgba(86, 149, 236, 0.2)",
    color: "#9CC6FF",
  },
});

const getTweetOrigin = ({
  is_cluster,
  is_share,
  is_base,
  is_comment,
  styles,
}) => {
  let text = "";
  let icon = null;
  let style = {};

  if (is_cluster) {
    text = "CLUSTER";
    icon = ClusterIcon;
    style = styles.cluster;
  } else if (is_base) {
    text = "ORIGINAL POST";
    icon = UserMaleIcon;
    style = styles.orig;
  } else if (is_share) {
    text = "SHARED POST";
    icon = UserMaleIcon;
    style = styles.is_share;
  } else if (is_comment) {
    text = "COMMENT";
    icon = CommentIcon;
    style = styles.is_comment;
  }

  return { text, icon, style };
};

export default function PostOrigin({
  is_cluster,
  is_comment,
  is_share,
  is_base,
}) {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const {
    text: OriginText,
    icon: OriginIcon,
    style,
  } = getTweetOrigin({
    is_cluster,
    is_comment,
    is_share,
    is_base,
    styles,
  });

  return (
    <Box data-testid="post-origin" sx={{ ...styles.roundBox, ...style }}>
      {OriginIcon && <OriginIcon />}
      {OriginText}
    </Box>
  );
}
