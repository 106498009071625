import { Typography, Link, Button, Tooltip, Avatar, Box } from "@mui/material";
import { ReactComponent as TwitterIcon } from "icons/social-media/Twitter.svg";
import { fixHighlightText, formatNumber, formatPostTime } from "utils/format";
import { ReactComponent as RedditIcon } from "icons/social-media/Reddit.svg";
import { ReactComponent as CopyClipboardIcon } from "icons/UI/copy.svg";
import { ReactComponent as ExtendedData } from "icons/NarBuilder/ExtendedData.svg";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import { useAppContext } from "context/Context";
import Analyze from "components/UI/AnalyzeBtn";
import {
  getUserPrefixByPlatform,
  HARMFUL_ENGAGEMENT_POSTS_LABEL,
  PLATFORMS,
  PLATFORM_CONFIG,
  USER_LABEL,
} from "utils/constants";
import { getPostURL, getSubredditURL, getUserURL } from "utils/fns";
import OpenIn from "components/UI/OpenIn";
import PostOrigin from "components/UI/PostOrigin";
import TextTogglable from "components/UI/TextTogglable";
import HandleRemoved from "components/HandleRemoved";
import HighlightedKeyword from "components/UI/HighlightedKeyword";
import ExcludeInclude from "./ExcludeInclude";
import { useFlags } from "launchdarkly-react-client-sdk";

const useStyles = (props) => ({
  root: {
    px: 3,
    py: 2,
  },
  timeIcon: {
    mr: 0.7,
    fontSize: "12px",
  },
  footer: {
    marginTop: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#86899D",
  },
  btn: {
    border: "1px solid #282C38",
    padding: 0,
    width: "28px",
    height: "28px",
    minWidth: "28px",
    borderRadius: "3px",
    color: "#6F7494",
    fontSize: "12px",
    "&:hover": {
      border: "1px solid #282C38",
    },
  },
  link: {
    textDecoration: "none",
    color: "text.light",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "16px",
  },
  timestamp: {
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "24px",
    color: "#6F7494",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    whiteSpace: "nowrap",
  },
  indicator: {
    width: "12px",
    marginRight: "5px",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dot: {
    width: "12px",
    height: "12px",
    backgroundColor:
      props.indicator === "manipulated"
        ? "rgba(198, 54, 63, 0.9)"
        : "rgba(44, 160, 44, 0.9)",
  },
  flex: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  origPost: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
  },
  clusterIcon: {
    marginRight: "5px",
    width: "24px",
    height: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      width: "15px",
      height: "15px",
    },
  },
  hoaxIcon: {
    padding: 0,
    minWidth: 0,
    marginLeft: "4px",
    marginRight: "4px",
    cursor: "pointer",
  },
  harmfulEng: {
    fontSize: "10px",
    lineHeight: "14px",
    fontWeight: "600px",
    color: "#AFAFAF",
  },
  userName: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "16.32px",
    color: "rgba(255,255,255,0.99)",
    display: "flex",
    alignItems: "center",
    marginRight: 1,
  },
  in: {
    color: "#6F7494",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "16.32px",
    mx: 0.5,
  },
  avatar: {
    width: "20px",
    height: "20px",
    marginRight: "6px",
  },
  Boxider: {
    margin: "0px 10px 0px 10px",
    fontWeight: "400",
    lineHeight: "35px",
    opacity: 0.3,
    fontSize: "12px",
  },
  extendedData: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontWeight: "500",
    color: "#BABDD9",
    cursor: "pointer",
  },
  extendedDataIcon: {
    marginRight: "5px",
  },
});

function Tweet({
  id,
  title,
  post_time,
  user_display_name,
  user_screen_name,
  indicator,
  noWrap,
  clusters = 0,
  known_hoax = 0,
  withHoax = false,
  withHarmfulEng = false,
  harmful_engagement = null,
  hoaxClick,
  analyze = false,
  seeInEntityExpl,
  is_cluster,
  profile_image_url,
  onCopy,
  onExcludeIncludeApply = () => {},
  ...rest
}) {
  const styles = useStyles({ indicator });
  const {
    state: { timezoneOffset, platform, narratives },
  } = useAppContext();

  const flags = useFlags();

  const platformConfig = PLATFORM_CONFIG[platform];

  const post_body = rest[platformConfig.post_text_field] || "";

  const user_url = getUserURL({ user_screen_name, platform });
  const post_url = getPostURL({
    user_screen_name,
    id,
    platform,
    subreddit_name: rest.subreddit_name,
    post_url: rest.post_url || rest.post_original_url,
  });

  let highlight_text = post_body;
  let post_title = title;

  if (rest.sample_posts && rest.sample_posts[0]?.highlight_text) {
    highlight_text = rest.sample_posts[0].highlight_text;
  } else if (rest.highlight_text) {
    highlight_text = rest.highlight_text;
  }

  // PREDASH-2748
  highlight_text = fixHighlightText(post_body, highlight_text);

  if (rest.sample_posts && rest.sample_posts[0]?.highlight_title) {
    post_title = rest.sample_posts[0].highlight_title;
  } else if (rest.highlight_title) {
    post_title = rest.highlight_title;
  }

  const subreddit_name =
    rest.subreddit_name ||
    rest.subreddit_dot_name ||
    (rest.sample_posts && rest.sample_posts[0]?.subreddit_dot_name);

  const domain_name =
    rest.domain || (rest.sample_posts && rest.sample_posts[0]?.domain);

  const subreddit_url = getSubredditURL(subreddit_name);

  const prefix = getUserPrefixByPlatform[platform];

  const openIn = post_url && <OpenIn href={post_url} border />;

  const copyClipboad = (
    <Tooltip title="Copy to Clipboard" placement="top">
      <Button
        data-testid="tweet-copy-clipboard-button"
        sx={styles.btn}
        variant={"outlined"}
        color={"secondary"}
        onClick={onCopy}
      >
        <CopyClipboardIcon sx={{ fontSize: "10px" }} />
      </Button>
    </Tooltip>
  );

  const subreddit = platform === PLATFORMS.reddit && subreddit_name && (
    <>
      <Typography component="span" sx={styles.in}>
        in
      </Typography>
      {
        <Link
          data-testid="tweet-subreddit-link"
          fontSize="small"
          underline="hover"
          sx={{ ...styles.link, color: "white" }}
          target="_blank"
          href={subreddit_url}
        >
          r/{subreddit_name}
        </Link>
      }
    </>
  );

  const domain = platformConfig.has_domains_tweet && domain_name && (
    <>
      <Typography component="span" sx={styles.in}>
        in
      </Typography>
      {
        <Link
          data-testid="tweet-domain-link"
          fontSize="small"
          underline="hover"
          sx={{ ...styles.link, color: "white" }}
          target="_blank"
          href={"https://" + domain_name}
        >
          {domain_name}
        </Link>
      }
    </>
  );

  const handleExcludeIncludeApply = ({ included, excluded }) => {
    onExcludeIncludeApply({
      id,
      included,
      excluded,
    });
  };

  const includedNarratives = narratives
    .filter((d) => {
      return (d.includePosts || []).includes(id);
    })
    .map((d) => d.narrativeId);

  const excludedNarratives = narratives
    .filter((d) => {
      return (d.excludePosts || []).includes(id);
    })
    .map((d) => d.narrativeId);

  return (
    <Box sx={styles.root}>
      {analyze && (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={styles.origPost}>
            {platformConfig.has_post_origin && (
              <Box sx={{ mr: 1.25 }}>
                <PostOrigin
                  is_cluster={is_cluster}
                  is_share={rest.is_share}
                  is_comment={rest.is_comment}
                  is_base={rest.is_base}
                />
              </Box>
            )}
            <Box sx={{ mr: 1.25 }}>{copyClipboad}</Box>
            {post_url && <Box>{openIn}</Box>}
          </Box>

          <Box>
            {flags.includeExcludePosts && (
              <ExcludeInclude
                onApply={handleExcludeIncludeApply}
                value={{
                  included: includedNarratives,
                  excluded: excludedNarratives,
                }}
              />
            )}
          </Box>
        </Box>
      )}

      {post_title && (
        <HandleRemoved text={post_title}>
          <Typography
            paragraph
            display={"block"}
            variant={"body2"}
            sx={{
              fontStyle: "normal",
              color: "text.light",
              fontWeight: 600,
              fontSize: "17px",
              lineHeight: "21px",
              marginBottom: "15px",
            }}
          >
            <HighlightedKeyword html={post_title} dataTestId={`tweet-post-title-${id}`} />
          </Typography>
        </HandleRemoved>
      )}

      <HandleRemoved text={highlight_text}>
        <TextTogglable
          text={highlight_text}
          sx={{
            fontSize:
              platform === PLATFORMS.reddit || !analyze ? "14px" : "17px",
            lineHeight:
              platform === PLATFORMS.reddit || !analyze ? "18px" : "25px",
            color: "text.light",
          }}
        />
      </HandleRemoved>

      <Box sx={styles.flex}>
        {analyze && (
          <Analyze
            onClick={() =>
              seeInEntityExpl(USER_LABEL, prefix + user_screen_name)
            }
          />
        )}

        <Box sx={styles.userName}>
          <Avatar data-testid="tweet-avatar" sx={styles.avatar} src={profile_image_url} />
          {user_url ? (
            <Link
              data-testid="tweet-user"
              underline="hover"
              sx={styles.link}
              target="_blank"
              href={user_url}
            >
              {prefix}
              {user_screen_name}
            </Link>
          ) : (
            <Box component="span" sx={styles.link}>
              {prefix}
              {user_screen_name}
            </Box>
          )}
          <div data-testid="tweet-subreddit">{subreddit}</div>
          <div data-testid="tweet-domain">{domain}</div>
        </Box>

        <Typography variant={"body2"} sx={styles.timestamp} data-testid="tweet-timestamp">
          <AccessTimeOutlinedIcon sx={styles.timeIcon} />
          {formatPostTime(post_time, timezoneOffset)}
        </Typography>

        {!rest.is_base && analyze && platformConfig.has_extended_data && (
          <>
            <Box sx={styles.Boxider}>|</Box>
            <Tooltip
              placement="top"
              title="Post does not meet the collection rule--perhaps due to being outside the collection time window or not matching other metadata filters--but was retweeted or commented on by at least one post that does meet the collection rule."
            >
              <Box sx={styles.extendedData}>
                <ExtendedData sx={styles.extendedDataIcon} />
                Extended Data
              </Box>
            </Tooltip>
          </>
        )}
      </Box>

      {/* NOT SHOWN IN ANALYZE  */}
      {analyze ? null : (
        <Box sx={styles.footer}>
          <Box sx={styles.flex}>
            {indicator && (
              <Box sx={styles.indicator}>
                <Box sx={styles.dot}></Box>
              </Box>
            )}

            {platform === "twitter" && (
              <TwitterIcon
                style={{ width: 20, height: 20, marginRight: "4px" }}
              />
            )}
            {platform === "reddit" && (
              <RedditIcon
                style={{ width: 20, height: 20, marginRight: "4px" }}
              />
            )}

            {withHoax && known_hoax > 0 && (
              <Button
                data-testid={id.replace(/\s/g, "-").toLowerCase()}
                sx={styles.hoaxIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  hoaxClick({
                    id,
                    platform,
                    post_url,
                    post_body,
                    post_time,
                    user_display_name,
                    user_screen_name,
                    indicator,
                    noWrap,
                    clusters,
                    known_hoax,
                    harmful_engagement,
                    ...rest,
                  });
                }}
              >
                <Box
                  component="i"
                  className="icon-knownhoax"
                  fontSize="14px"
                  sx={{ color: "#E7394F" }}
                />
              </Button>
            )}

            {withHarmfulEng &&
              platformConfig.has_engagements &&
              harmful_engagement !== null && (
                <Box sx={styles.harmfulEng}>
                  {HARMFUL_ENGAGEMENT_POSTS_LABEL}:{" "}
                  {formatNumber(harmful_engagement)}
                </Box>
              )}
          </Box>
          {openIn}
        </Box>
      )}
    </Box>
  );
}

export default Tweet;
