import { Link, Avatar, Box, useTheme } from "@mui/material";
import UserIcons from "utils/user-icons";
import Analyze from "components/UI/AnalyzeBtn";
import { useAppContext } from "context/Context";
import {
  USER_LABEL,
  getUserPrefixByPlatform,
  PLATFORM_CONFIG,
  ANONYMOUS_TOOLTIP,
} from "utils/constants";
import OpenIn from "components/UI/OpenIn";
import Hint from "components/UI/Hint";

const useStyles = ({theme}) => ({
  root: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    paddingLeft: "14px",
  },
  avatar: {
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  separator: {
    margin: "0px 6px",
  },
  separatorSm: {
    margin: "0px 3px",
  },
  dot: {
    margin: "0px 5px 0px 0px",
    alignContent: "center",
    fontSize: "16px",
  },
  username: {
    fontSize: "13px",
    fontWeight: "normal",
    color: "#fff",
    lineHeight: "16px",
  },
});

const UserCell = ({
  profile_image_url,
  profile_url,
  screen_name,
  verified,
  seeInEntityExpl = null,
}) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const UserIcon = UserIcons[screen_name];

  const {
    state: { platform },
  } = useAppContext();

  const prefix = getUserPrefixByPlatform[platform];
  const platformConfig = PLATFORM_CONFIG[platform];

  const showLink = profile_url && profile_url.startsWith("http");

  return (
    <Box sx={styles.root}>
      {platformConfig.has_open_author && <OpenIn href={profile_url} />}

      {seeInEntityExpl && (
        <Analyze
          sx={styles.button}
          onClick={() => seeInEntityExpl(USER_LABEL, prefix + screen_name)}
        />
      )}

      <Avatar
        alt={screen_name}
        sx={styles.avatar}
        src={profile_image_url}
      />

      {showLink ? (
        <Link
          target="_blank"
          href={profile_url}
          sx={styles.username}
          underline="hover"
        >
          {prefix}
          {screen_name}
        </Link>
      ) : (
        prefix + screen_name
      )}

      {verified && (
        <>
          <span>&nbsp;</span>
          <Box component="i" className="icon-verified" />
        </>
      )}

      {UserIcon && (
        <>
          <span>&nbsp;</span>
          <UserIcon />
        </>
      )}
      
      {screen_name === "Anonymous" && (
        <>
          <span>&nbsp;</span>
          <Hint tooltipText={ANONYMOUS_TOOLTIP} dataTestId="AnonymousIcon"></Hint>
        </>
      )}
    </Box>
  );
};

export default UserCell;
