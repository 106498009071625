import { Box, Link, Tooltip } from "@mui/material";
import { useAppContext } from "context/Context";
import { PLATFORM_CONFIG } from "utils/constants";

const useStyles = (props) => ({
  linkIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: props.border ? "1px solid #282C38" : "none",
    padding: 0,
    width: "28px",
    height: "28px",
    minWidth: "28px",
    borderRadius: "3px",
    color: "#6F7494",
    "&:hover": {
      border: props.border ? "1px solid #282C38" : "none",
    },
  },
});

const OpenIn = ({ href, border }) => {
  const styles = useStyles({ border });
  const {
    state: { platform },
  } = useAppContext();

  const platformConfig = PLATFORM_CONFIG[platform];
  const title = `View on ${platformConfig?.name || platform}`;

	return (
		<Tooltip title={title} placement="top">
			<Link
				data-testid="tweet-open-in-new-tab"
				underline={"none"}
				sx={styles.linkIcon}
				target="_blank"
				href={href}
			>
				<Box component="i" className="icon-openinnewtab" sx={{ fontSize: "12px" }}  />
			</Link>
		</Tooltip>
	);
};

export default OpenIn;
