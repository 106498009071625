import {
  HARMFUL_ENGAGEMENT_POSTS_LABEL,
  PLATFORMS,
  PLATFORM_CONFIG,
  USER_LABEL,
} from "utils/constants";

export const totalPosts = (platform) => {
  const platformConfig = PLATFORM_CONFIG[platform];
  return [
    {
      agg_type: "sum",
      field_name: "is_orig",
      return_name: "original_post_ct",
      label: "Original Posts",
      tooltipText:
        "Posts that are not a repost, but rather unique content entered by the account of interest.",
      id: "DashboardOrginalPosts",
    },
    ...(platform === PLATFORMS.twitter
      ? [
          {
            agg_type: "sum",
            field_name: "post_engagement.is_share",
            return_name: "share_post_ct",
            label:
              platform === PLATFORMS.twitter
                ? "Local Shared Posts"
                : "Shared Posts",
            tooltipText:
              platform === PLATFORMS.twitter
                ? "Retweets captured within the original data collection."
                : "Posts that are reposted content.",
            id: "DashboardLocalSharedPosts",
          },
        ]
      : []),
    ...(platformConfig.has_anonymous_users
      ? [
          {
            agg_type: "sum",
            field_name: "user_is_anonymous",
            return_name: "user_is_anonymous_ct",
            label: `Anonymous Posts`,
            tooltipText: platformConfig.anonymous_posts_tooltip || "",
            hidePercentage: false,
          },
        ]
      : []),
    {
      agg_type: "sum",
      field_name: "is_manipulated",
      return_name: "manipulated_post_ct",
      label: "Anomalous Posts",
      tooltipText: "Posts showing atypical patterns of content propagation.",
      id: "DashboardAnomalousPosts",
    },
    {
      agg_type: "sum",
      field_name: "is_toxic",
      return_name: "toxic_post_ct",
      label: "Toxic Posts",
      tooltipText:
        "Posts containing offensive speech, hate speech, insults or threats.",
      id: "DashboardToxicPosts",
    },
  ];
};

export const postHierarchy = (platform) => {
  const platformConfig = PLATFORM_CONFIG[platform];

  const stats = []

  if (platformConfig.has_domains_stats) {
    stats.push(
      {
        agg_type: "cardinality",
        field_name: "domain.id.keyword",
        return_name: "domains_ct",
        label: "Domains",
        tooltipText: "",
        hidePercentage: true,
      }
    );
  }

  if (platform === PLATFORMS.reddit) {
    stats.push(
      {
        agg_type: "cardinality",
        field_name: "subreddit.id.keyword",
        return_name: "subreddit_ct",
        label: "Subreddits",
        tooltipText: "",
        hidePercentage: true,
      }
    );
  }

  return stats
};

export const totalEngagements = (platform) => {
  const platformConfig = PLATFORM_CONFIG[platform]
  const commonCols = [
    {
      agg_type: "sum",
      field_name: "harmful_engagements",
      return_name: "harmful_engagements",
      label: HARMFUL_ENGAGEMENT_POSTS_LABEL,
      id: "Engagements_on_High_Risk_Posts",
      tooltipText: platformConfig.engagement_tooltip_detailed,
    },
  ];

  const twitterCols = [
    {
      agg_type: "sum",
      field_name: "post_engagement.like_count",
      return_name: "like_ct",
      label: "Likes",
      id: "Likes",
    },
    {
      agg_type: "sum",
      field_name: "post_engagement.share_count",
      return_name: "share_ct",
      label: platform === PLATFORMS.twitter ? "Global Shares" : "Shares",
      id: "globalShares",
      tooltipText:
        platform === PLATFORMS.twitter
          ? "Retweets for this post at the time of data ingestion."
          : "",
    },
  ];

  const redditCols = [
    {
      usesDefaultResponse: true,
      label: "Positive Submission Scores",
      field_name: "positive_submission_score",
    },
    {
      usesDefaultResponse: true,
      label: "Negative Submission Scores",
      field_name: "negative_submission_score",
    },
    {
      agg_type: "sum",
      field_name: "post_engagement.comment_count",
      return_name: "comment_ct",
      label: "Comments",
    },
  ];

  const cols = {
    [PLATFORMS.twitter]: twitterCols,
    [PLATFORMS.reddit]: redditCols,
  };

  return [...commonCols, ...(cols[platform] || [])];
};

export const totalUsers = (platform) => {
  const platformConfig = PLATFORM_CONFIG[platform];

  const stats = [];

  if (platformConfig.has_bot_like) {
    stats.push({
      agg_type: "cardinality",
      field_name: "user_is_bot",
      return_name: "bot_user_ct",
      label: "Bot-like " + USER_LABEL,
      id: "Bot_Like_Authors",
      tooltipText:
        "Displaying behavior consistent with that of a bot or click farm, where there is some degree of automation or other advanced strategies to produce rapid, high volume dissemination of content.",
    });
  }

  if (platformConfig.has_domains_stats) {
    stats.push(
      {
        agg_type: "cardinality",
        field_name: "user.id.keyword",
        return_name: "user_ct",
        label: USER_LABEL,
        tooltipText: "",
        hidePercentage: true,
      }
    );
  }
  return stats;
};

export const sentiments = [
  {
    agg_type: "sum",
    field_name: "is_positive",
    return_name: "positive_ct",
    id: "Positive_Sentiment",
    label: "Positive",
  },
  {
    agg_type: "sum",
    field_name: "is_negative",
    return_name: "negative_ct",
    id: "Negative_Sentiment",
    label: "Negative",
  },
  {
    agg_type: "sum",
    field_name: "is_neutral",
    return_name: "neutral_ct",
    id: "Neutral_Sentiment",
    label: "Neutral",
  },
];

export const emotions = [
  {
    agg_type: "sum",
    field_name: "is_neutral_emotion",
    return_name: "neutral_emotion_ct",
    label: "Neutral",
    id: "Neutral_Emotion"
  },
  {
    agg_type: "sum",
    field_name: "is_anger",
    return_name: "anger_ct",
    label: "Anger",
    id: "Anger_Emotion"
  },
  {
    agg_type: "sum",
    field_name: "is_happiness",
    return_name: "hapiness_ct",
    label: "Happiness",
    id: "Happy_Emotion"
  },
  {
    agg_type: "sum",
    field_name: "is_disgust",
    return_name: "disgust_ct",
    label: "Disgust",
    id: "Disgust_Emotion"
  },
  {
    agg_type: "sum",
    field_name: "is_sadness",
    return_name: "sadness_ct",
    label: "Sadness",
    id: "Sadness_Emotion"
  },
  {
    agg_type: "sum",
    field_name: "is_surprise",
    return_name: "surprise_ct",
    label: "Surprise",
    id: "Surprise_Emotion"
  },
  {
    agg_type: "sum",
    field_name: "is_fear",
    return_name: "fear_ct",
    label: "Fear",
    id: "Fear_Emotion"
  },
];

export const totalUsersReturnField = [
  {
    agg_type: "cardinality",
    field_name: "user.id.keyword",
    return_name: "user_ct",
  },
];

export const totalDomainsReturnFields = [
  {
    agg_type: "cardinality",
    field_name: "domain.id.keyword",
    return_name: "domain_ct",
  },
];

export const defaultReturnFields = [
  {
    agg_type: "cardinality",
    field_name: "user.id.keyword",
    return_name: "user_ct",
  },
  {
    agg_type: "max",
    field_name: "created_at",
    return_name: "end_date",
  },
  {
    agg_type: "min",
    field_name: "created_at",
    return_name: "start_date",
  },
  {
    agg_type: "sum",
    field_name: "post_engagement.total",
    return_name: "engagement",
  },
];
