import { Box, Button, Drawer } from "@mui/material";
import { useAppContext } from "context/Context";
import { ACTIONS } from "context/reducer";
import Explanation from "components/UI/Explanation";
import ExplanationEntity from "./ExplanationEntity";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const styles = {
  root: {},
  btn: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "16px",
    color: "rgba(255,255,255,0.6)",
  },
  drawer: {
    zIndex: 9999,
    flexShrink: 0,
    width: "380px",
    height: "100vh",
  },
  drawerPaper: {
    width: "380px",
  },
  header: {
    height: "63.39px !important",
    borderBottom: "1px solid #282C38",
    px: 1,
    py: 3.8,
    display: "flex",
    alignItems: "center",
  },
  backBtn: {
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "16px",
    textTransform: "capitalize",
  },
  content: {
    padding: 2,
  },
  postWrap: {
    backgroundColor: "#333146",
    borderRadius: "6px",
  },
  entityWrap: {
    marginBottom: "4px",
  },
  url: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
};

export default function ExplanationDrawer() {
  const {
    dispatch,
    state: { explanation, explanationDrawerOpen: open },
  } = useAppContext();

  const handleClose = () => {
    dispatch({
      type: ACTIONS.SET_EXPLANATION,
      payload: {
        explanationDrawerOpen: false,
      },
    });
  };

  return (
    <Drawer
      sx={{
        ...styles.drawer,
        "& .MuiDrawer-paper": styles.drawerPaper,
      }}
      anchor={"right"}
      open={open}
      onClose={handleClose}
    >
      <Box sx={styles.header}>
        <Button
          data-testid="explanation-drawer-details-button"
          variant="h3"
          startIcon={<ArrowBackIcon />}
          sx={styles.backBtn}
          onClick={handleClose}
        >
          {explanation.entityType} Details
        </Button>
      </Box>
      <Box sx={styles.content}>
        {explanation && explanation.entity && (
          <ExplanationEntity
            type={explanation.entityType}
            entity={explanation.entity}
          />
        )}

        {explanation && <Explanation {...explanation} />}
      </Box>
    </Drawer>
  );
}
