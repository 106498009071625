import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  MenuList,
  Popover,
  TextField,
  Typography,
  useTheme,
  Box,
} from "@mui/material";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useEffect, useRef, useState } from "react";
import { useAppContext } from "context/Context";
import SearchIcon from '@mui/icons-material/Search';
import { compareArrays } from "pages/DataUploader/utils/utils";
import useEventTracker from "api/hooks/useEventTracker";

const useStyles = ({theme}) => ({
  popoverRoot: {},
  popoverPaper: {
    background: theme.palette.background.$2,
    border: "1px solid " + theme.palette.background.$3,
  },
  iconButton: {
    height: "30px",
    padding: "0px 2.5px",
    borderRadius: "50%",
    color: "#6F7494",
    "& svg": {
      pointerEvents: "none",
    },
    "&.active, &:active": {
      color: "#2BE7A0",
    },
    "&:not(.active):hover": {
      color: "#ffffff",
    },
  },
  list: {
    padding: "0px 10px",
    maxHeight: "320px",
    minHeight: "220px",
    overflowY: "auto",
  },
  inputItem: {
    position: "sticky",
    top: 0,
    zIndex: 2,
    width: "100%",
    padding: theme.spacing(2, 1.5),
    backgroundColor: theme.palette.background.$2,
  },
  searchInput: {
    width: "100%",
    background: theme.palette.background.$1,
    border: "1px solid #282C38",
    borderRadius: "2px",
    fontWeight: "400",

    "& .MuiOutlinedInput-input": {
      padding: theme.spacing(0.4, 1, 0.4, 0.5),

      "&::placeholder": {
        color: "#6F7494",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "15px",
      },
    },

    "& .MuiInputBase-root": {
      borderRadius: 0,
      paddingLeft: "5px",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      boxShadow: "0px 0px 1px #000000, 0px 1px 0px rgba(255, 255, 255, 0.06)",
    },
  },
  root: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    height: "32px",
    color: "rgba(193, 191, 215, 0.99)",
    paddingLeft: "2px",
    paddingRight: "5px",

    "&:hover": {
      "& span.MuiTypography-root": {
        color: "#fff !important",
      },
    },
  },
  formLabel: {
    margin: 0,
    width: "100%",
    verticalAlign: "baseline",
    "& .MuiCheckbox-root:not(.Mui-checked)": {
      color: "#6F7494",
    },
    "& .MuiTypography-root": {
      color: "#AFAFAF",
      fontSize: "14px",
      lineHeight: "18px",
      fontWeight: "400",
      wordBreak: "break-word",
    },
    "&:hover .MuiTypography-root:not(.Mui-disabled)": {
      color: "#FFFFFF",
    },
    "&.Mui-disabled .MuiTypography-root": {
      opacity: 0.5,
    },
  },
  footer: {
    textAlign: "center",
    padding: "5px",
    borderTop: "1px solid " + theme.palette.background.$3,
  },
  title: {
    marginTop: "12px",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#6F7494",
  },
});

const ExcludeInclude = ({ onApply, value }) => {
  // Hooks
  const theme = useTheme()
  const styles = useStyles({ theme });

  const {
    state: { narratives },
  } = useAppContext();

  const inputRef = useRef();
  const tracker = useEventTracker();

  // State
  const [anchorEl, setAnchorEl] = useState(null);
  const [operator, setOperator] = useState("include");

  const [excluded, setExcluded] = useState(value.excluded);
  const [included, setIncluded] = useState(value.included);

  useEffect(() => {
    setIncluded(value.included);
    setExcluded(value.excluded);
  }, [value]);

  const [visibleOptions, setVisibleOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [currentSelected, setCurrentSelected] = useState([]);

  const open = Boolean(anchorEl);
  const narrativeOptions = narratives
    .filter((d) => d.name !== "All")
    .map((d) => ({
      label: d.name,
      value: d.id,
    }));

  // Effects
  useEffect(() => {
    setVisibleOptions(narratives.map((d) => d.id));
  }, [narratives]);

  // Functions
  const handleClose = () => setAnchorEl(null);

  const handleCheckboxChange = (value, checked) => {
    let newArr = [...currentSelected];

    if (checked) {
      newArr.push(value);
    } else {
      newArr = newArr.filter((d) => d !== value);
    }

    setCurrentSelected(newArr);
  };

  const handleSearch = (e) => {
    e.stopPropagation();
    setSearchTerm(e.target.value);
    const val = e.target.value.toLowerCase();
    const filtered = narrativeOptions
      .filter((d) => {
        return (
          d.label.toLowerCase().includes(val) ||
          (d.groupName || "").toLowerCase().includes(val)
        );
      })
      .flatMap((d) => [d.value, d.groupName]);
    setVisibleOptions(filtered);
  };

  const handleKeyDown = (e) => {
    e.stopPropagation();
  };

  const handleRemoveIconClick = (e) => {
    setCurrentSelected(excluded);
    setOperator("exclude");
    setAnchorEl(e.target);
  };

  const handleAddIconClick = (e) => {
    setCurrentSelected(included);
    setOperator("include");
    setAnchorEl(e.target);
  };

  const handleApply = () => {
    operator === "include"
      ? setIncluded(currentSelected)
      : setExcluded(currentSelected);

    handleClose();

    tracker.track(
			"Applied narrative include/exclude",
			"apply",
			{
				operator,
				currentSelected,
			},
			"Analyze / include-exclude ",
		);

    setTimeout(() => onApply({
      included: operator === "include" ? currentSelected : included,
      excluded: operator === "exclude" ? currentSelected : excluded, 
    }), 0);
  };

  const title =
    operator === "include"
      ? "Add to Narrative(s)"
      : "Exclude from Narrative(s)";

  const applyActive =
    operator === "include"
      ? !compareArrays(included, currentSelected, (a, b) => a === b)
      : !compareArrays(excluded, currentSelected, (a, b) => a === b);

  return (
    <>
      <div>
        <IconButton
          onClick={(e) => {
            handleRemoveIconClick(e);
          }}
          disableRipple
          sx={styles.iconButton}
          className={excluded.length ? "active" : ""}
        >
          <RemoveCircleIcon sx={{fontSize: "20px"}} />
        </IconButton>
        <IconButton
          onClick={(e) => {
            handleAddIconClick(e);
          }}
          disableRipple
          sx={styles.iconButton}
          className={included.length ? "active" : ""}
        >
          <AddCircleIcon sx={{fontSize: "20px"}} />
        </IconButton>
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPopover-paper": styles.popoverPaper
        }}
        transformOrigin={{ vertical: -5, horizontal: 0 }}
      >
        <Box sx={styles.list}>
          <MenuList sx={{ padding: 0 }}>
            <Box sx={styles.inputItem}>
              <TextField
                variant="outlined"
                sx={styles.searchInput}
                size="small"
                value={searchTerm}
                onKeyDown={handleKeyDown}
                onChange={handleSearch}
                inputRef={inputRef}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder="Search"
              />
              {title && (
                <Typography sx={styles.title}>{title}</Typography>
              )}
            </Box>

            {narrativeOptions.map((option) => {
              return (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  style={{ maxWidth: 600 }}
                  sx={{
                    ...styles.root,
                    "& .Mui-selected": styles.selected,
                    display: visibleOptions.includes(option.value) ? null : "none !important",
                  }}
                  disabled={!!option.disabled}
                >
                  <FormControlLabel
                    sx={styles.formLabel}
                    control={
                      <Checkbox
                        data-testid={`exclude-include-${option.value}-checkbox`}
                        size="small"
                        checked={currentSelected.indexOf(option.value) > -1}
                        onChange={(e) =>
                          handleCheckboxChange(option.value, e.target.checked)
                        }
                        disableRipple
                      />
                    }
                    label={option.label}
                  />
                </MenuItem>
              );
            })}
          </MenuList>
        </Box>
        <Box sx={styles.footer}>
          <Button
            data-testid={"exclude-include-apply-button"}
            variant="text"
            onClick={handleApply}
            color={applyActive ? "primary" : "white"}
          >
            Apply
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default ExcludeInclude;
