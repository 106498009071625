import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

const icons = {
	swati_gs: RemoveRedEyeOutlinedIcon,
	CookedHillary: RemoveRedEyeOutlinedIcon,
	"DoubleMinorityy ": RemoveRedEyeOutlinedIcon,
	CarmineSabia: RemoveRedEyeOutlinedIcon,
	MichaelCoudrey: RemoveRedEyeOutlinedIcon,
	THeinrich22: RemoveRedEyeOutlinedIcon,
	sonic1930: WarningAmberIcon,
};

export default icons;
