import { PLATFORM_BASE_URLS, PLATFORM_USER_PATH, PLATFORMS } from "./constants";

export const getUserURL = ({ user_screen_name, platform }) => {
	if (PLATFORM_BASE_URLS[platform]) {
		return `${PLATFORM_BASE_URLS[platform]}${PLATFORM_USER_PATH[platform]}/${user_screen_name}`;
	}

	return "";
};

export const getPostURL = ({ user_screen_name, id, platform, subreddit_name, post_url }) => {
	if (post_url) {
		return post_url;
	}

	if (platform === PLATFORMS.reddit) {
		if (subreddit_name) {
			return getSubredditURL(subreddit_name);
		} else {
			return getUserURL({ user_screen_name, platform });
		}
	}

	return `${PLATFORM_BASE_URLS[platform]}/${user_screen_name}/status/${id}`;
};


export const getSubredditURL = (subreddit_name) => {
	if (subreddit_name) {
		return `${PLATFORM_BASE_URLS.reddit}/r/${subreddit_name}`;
	}
	return undefined;
};

export const abbreviateNumber = (documentCount) => {
	if(documentCount) {
		if(documentCount > 999999) {
			return (documentCount/1000000).toFixed(1) + "m"; 
		}

		if(documentCount > 999) {
			return (documentCount/1000).toFixed(1) + "k";
		}
	}

	return documentCount;
};