import { EXPLANATIONS } from "utils/constants";
import { ReactComponent as CopyClipboardIcon } from "icons/UI/copy.svg";
import { ReactComponent as HarmIcon } from "icons/UI/Harm.svg";
import { Box, Button, Typography, useTheme } from "@mui/material";
import ManipBigIcon from "icons/Explanation/Manip.svg";
import ToxicBigIcon from "icons/Explanation/Toxic.svg";
import HarmBigIcon from "icons/Explanation/Harm.svg";
import { useEffect, useState } from "react";
import { getExplanation } from "api/endpoints/AnalyticsApi";
import { buildExplanationText } from "utils/format";

const getBackgroundImage = (type) => {
  let iconUrl = "";

  if (type === EXPLANATIONS.anomalous) {
    iconUrl = ManipBigIcon;
  } else if (type === EXPLANATIONS.toxicity) {
    iconUrl = ToxicBigIcon;
  } else if (type === EXPLANATIONS.harm) {
    iconUrl = HarmBigIcon;
  }

  return `url(${iconUrl})`;
};

const useStyles = ({ theme, ...props }) => ({
  card: {
    backgroundColor: props.backgroundColor,
    borderRadius: "6px",
  },
  reason: {
    color: props.textColor,
    fontSize: "12px",
    lineHeight: "17px",
    fontWeight: "normal",
    marginBottom: theme.spacing(1.5),
  },
  text: {
    fontSize: "13px",
    lineHeight: "19px",
    fontWeight: "normal",
  },
  header: {
    padding: theme.spacing(2),
    borderBottom: "1px solid " + props.borderColor,
    display: "flex",
    alignItems: "center",
  },
  icon: {
    borderRadius: "6px",
    width: "32px",
    height: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: theme.spacing(2),
    backgroundColor:
      props.type === EXPLANATIONS.anomalous ? "#2D2B3C" : props.backgroundColor,
  },
  label: {
    fontSize: "15px",
    lineHeight: "16px",
    fontWeight: "800",
  },
  content: {
    padding: theme.spacing(2),
    backgroundImage: getBackgroundImage(props.type),
    backgroundRepeat: "no-repeat",
    backgroundPosition: "90% center",
  },
  copyBtn: {
    color: "#D0D0D3",
    fontWeight: "normal",
  },
});

const getConfig = (type, theme) => {
  const { background, text, border } = theme.palette;

  let backgroundColor = null;
  let textColor = null;
  let borderColor = null;

  if (type === EXPLANATIONS.harm) {
    backgroundColor = background.harmExpl;
    textColor = text.harmExpl;
    borderColor = border.harmExpl;
  } else if (type === EXPLANATIONS.toxicity) {
    backgroundColor = background.toxicExpl;
    textColor = text.toxicExpl;
    borderColor = border.toxicExpl;
  } else if (type === EXPLANATIONS.anomalous) {
    backgroundColor = background.anomalousExpl;
    textColor = text.anomalousExpl;
    borderColor = border.anomalousExpl;
  }

  const conf = {
    [EXPLANATIONS.harm]: {
      label: "Risk",
      icon: <HarmIcon />,
      textColor: "",
    },
    [EXPLANATIONS.toxicity]: {
      label: "Toxic",
      icon: (
        <Box
          component="i"
          className="icon-toxic"
          fontSize="1rem"
          sx={{ color: "#B133FF" }}
        />
      ),
    },
    [EXPLANATIONS.anomalous]: {
      label: "Anomalous",
      icon: (
        <Box
          component="i"
          className="icon-anomalous"
          fontSize="1rem"
          sx={{ color: "#6F749C" }}
        />
      ),
    },
  };

  return {
    ...conf[type],
    backgroundColor,
    textColor,
    borderColor,
  };
};

export default function Explanation({ type, entityType }) {
  const theme = useTheme();
  const { backgroundColor, textColor, borderColor, icon, label } = getConfig(
    type,
    theme
  );

  const styles = useStyles({ backgroundColor, textColor, borderColor, type });

  const [text, setText] = useState("");

  useEffect(() => {
    const loadExpl = async () => {
      const expl = await getExplanation(entityType, type);
      console.log(expl);
      const _text = buildExplanationText(expl);
      setText(_text);
    };
    loadExpl();
  }, [type, entityType]);

  return (
    <Box sx={styles.card}>
      <Box sx={styles.header}>
        <Box sx={styles.icon}>{icon}</Box>
        <Typography variant="h5" sx={styles.label}>
          {label}
        </Typography>
      </Box>
      <Box sx={styles.content}>
        <Typography variant="h5" sx={styles.reason}>
          REASON:
        </Typography>

        <Typography
          paragraph
          display={"block"}
          variant={"body2"}
          sx={styles.text}
          dangerouslySetInnerHTML={{ __html: text }}
        ></Typography>

        <Box>
          <Button
            data-testid="copy-to-clipboard-button"
            type="text"
            startIcon={<CopyClipboardIcon />}
            sx={styles.copyBtn}
          >
            Copy to Clipboard
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
