import { Avatar, Button, Menu, MenuItem, Typography } from "@mui/material";
import { useAppContext } from "context/Context";
import { ACTIONS } from "context/reducer";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import UserAccountDrawer from "./UserAccountDrawer";
import useEventTracker from "api/hooks/useEventTracker";

const UserInfo = () => {
  const tracker = useEventTracker();
  const {
    state: { user },
    dispatch,
  } = useAppContext();

  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const history = useHistory();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    tracker.track(
      "Clicked account menu",
      "click",
      "",
      "Top bar / account menu"
    );
  };

  const handleEditClick = () => {
    setDrawerOpen(true);
    tracker.track(
      "Clicked edit profile",
      "click",
      "",
      "Top bar / account menu"
    )
  };
  const handleDrawerClose = () => setDrawerOpen(false);

  const logout = () => {
    tracker.track(
      "Clicked log out",
      "click",
      "",
      "Top bar / account menu"
    )
    localStorage.clear();

    history.push("/login");
    dispatch({ type: ACTIONS.LOGOUT });
  };

  const username = user.username;
  const open = Boolean(anchorEl);

  return (
    <>
      <Button
        id="userNameButton"
        data-testid="user-info-button"
        onClick={handleOpen}
        sx={{
          background: "transparent",
          "&:hover": {
            background: "transparent",
          },
        }}
        aria-controls={open ? "userInfoPopup" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disableElevation
        disableRipple
      >
        <Avatar
          src={null}
          sx={{
            width: 32,
            height: 32,
            backgroundColor: "indigo.$5",
            fontWeight: "normal",
            fontSize: "16px",
          }}
        >
          {username ? username[0].toUpperCase() : ""}
        </Avatar>
      </Button>
      <Menu
        id="userInfoPopup"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "userNameButton",
        }}
      >
        <Typography
          sx={{
            fontWeight: "normal",
            fontSize: "12px",
            lineHeight: "18px",
            color: "text.muted",
            padding: "8px 20px 5px 20px",
          }}
        >
          {username}
        </Typography>

        <MenuItem onClick={handleEditClick} id="EditProfileButton" data-testid="edit-profile-button">
          Edit profile
        </MenuItem>

        <MenuItem onClick={logout} id="SignOUTButton" data-testid="logout-button">
          Log out
        </MenuItem>
      </Menu>

      <UserAccountDrawer open={drawerOpen} handleClose={handleDrawerClose} />
    </>
  );
};

export default UserInfo;
