import {
  EMOTIONS,
  EMOTION_MAPPINGS,
  SENTIMENTS,
  SENTIMENT_MAPPINGS,
  DEFAULT_PROFILE,
  PLATFORM_CONFIG,
  USER_COLUMN_LABEL,
  AUTHOR_GROUPS_LIMIT,
} from "utils/constants";
import { formatElasticSearchLabel, formatNumberkANDcomma } from "utils/format";
import lodashObject from "lodash/object";

export const FILTER_TYPES = {
  min_max: "min_max",
  categorical: "categorical",
  boolean: "boolean",
  date: "date",
  custom: "custom",
};

const DEFAULT_HARM_OPTIONS = [
  {
    label: "Low",
    value: "Low",
    num_threshold: 0.25,
    field: "low",
    profile: DEFAULT_PROFILE.id,
  },
  {
    label: "Medium",
    value: "Medium",
    num_threshold: 0.5,
    field: "medium",
    profile: DEFAULT_PROFILE.id,
  },
  {
    label: "High",
    value: "High",
    num_threshold: 0.75,
    field: "high",
    profile: DEFAULT_PROFILE.id,
  },
  {
    label: "Very High",
    value: "Very High",
    num_threshold: 1,
    field: "very_high",
    profile: DEFAULT_PROFILE.id,
  },
];

const checkIfExists = (val) => {
  return val !== "" && val !== undefined;
};

// No need to keep options for __filter reference
const cleanFilter = (filter) => {
  return {
    ...filter,
    options: [],
  };
};

export const getFilterObj = (filter, value, isDefaultFilter) => {
  // this empty object will be return as a signal to delete the filter
  // -1 will mean delete
  const empty = {
    [filter.field]: {
      real: {},
      pillText: "",
      active: false,
      value: -1,
      isDynamic: filter.isDynamic,
      elasticSearchField: filter.elasticSearchField,
      __filter: cleanFilter(filter),
    },
  };

  switch (filter.type) {
    case FILTER_TYPES.min_max: {
      let obj = {};

      if (checkIfExists(value[0]) && checkIfExists(value[1])) {
        obj = {
          ["min_" + filter.field]: +value[0],
          ["max_" + filter.field]: +value[1],
        };
      } else if (checkIfExists(value[0])) {
        obj = {
          ["min_" + filter.field]: +value[0],
        };
      } else if (checkIfExists(value[1])) {
        obj = {
          ["max_" + filter.field]: +value[1],
        };
      } else {
        return empty;
      }

      return {
        [filter.field]: {
          __filter: cleanFilter(filter),
          real: obj,
          active: true,
          pillText: filter.getPillText(filter, null, value),
          value,
          isDynamic: filter.isDynamic,
          elasticSearchField: filter.elasticSearchField,
          isDefaultFilter,
        },
      };
    }

    case FILTER_TYPES.boolean:
    case FILTER_TYPES.categorical: {
      const rootField = filter.field;
      const isBoolean = filter.boolean; // filter is boolean, means all children are booleans
      const isMultiSelect = filter.multi_select;

      const selectedOptions = isBoolean
        ? filter.options
        : filter.options.filter((d) => value.includes(d.value));

      if (selectedOptions.length) {
        let real = {};

        if (isBoolean) {
          selectedOptions.forEach((option) => {
            const selected = value.includes(option.value);

            let v = selected;

            if (option.inverted) {
              if (selected) v = undefined;
              else v = true;
            }

            real[option.field] = v;
          });
        } else if (isMultiSelect) {
          real[rootField] = selectedOptions.map((option) => option.field);
        } else {
          selectedOptions.forEach((option) => {
            real[option.field] = option.boolean ? true : option.threshold;
          });
        }

        // Add properties inside of an object instead of at the top level,
        // if 'requestPropertyGroup' is set on the filter configuration.
        if (filter.requestPropertyGroup) {
          real = { [filter.requestPropertyGroup]: real };
        }

        return {
          [filter.field]: {
            __filter: cleanFilter(filter),
            pillText: filter.getPillText(filter, selectedOptions, value),
            real,
            active: true,
            value,
            isDynamic: filter.isDynamic,
            elasticSearchField: filter.elasticSearchField,
            isDefaultFilter,
          },
        };
      }

      return empty;
    }
    default:
      return empty;
  }
};

const getMinMaxText = (filter, option, value) => {
  const [min, max] = value;

  let txt = "";

  if (min !== "" && max !== "") {
    txt = `${formatNumberkANDcomma(min)}-${formatNumberkANDcomma(max)}`;
  } else if (min !== "") {
    txt = `> ${formatNumberkANDcomma(min)}`;
  } else if (max !== "") {
    txt = `< ${formatNumberkANDcomma(max)}`;
  }

  if (txt) {
    return txt + " " + filter.title;
  }

  return "";
};

const getCategoricalText = (filter, option, selectedOptions) => {
  let firstTwo = selectedOptions.slice(0, 2);
  const rest = selectedOptions.slice(2);

  if (filter?.isFormatted) {
    firstTwo = firstTwo.map((f) => formatElasticSearchLabel(f));
  } else if (filter?.field?.includes("subreddit")) {
    firstTwo = firstTwo.map(f => `r/` + f)
  }

  return (
    (filter?.inverted ? "Hiding " : "") +
    firstTwo.join(", ") +
    (rest.length ? ` +${rest.length}` : "")
  );
};

const getBooleanText = (filter) => {
  return filter.title;
};

const getDynamicFilterPillText = (filter, options, values) => {
  const prefix = filter.field === 'ai_narratives' ? filter.title + ": " : "";
  return prefix + getCategoricalText(filter, options, values);
}

const FILTER_GROUPS = {
  classifiers: {
    name: "Classifiers",
    order: 2,
  },
  post_attributes: { name: "Post attributes", order: 0 },
  authors: { name: "Authors", order: 1 },
};

// TODO: create group field in strapi and remove this function
export const getFilterGroup = (filter) => {
  let group = null;
  switch (filter.title) {
    case "Language":
    case "Toxicity":
    case "Topics":
    case "Cohorts":
    case "AI Narratives":
    case "AI Narrative":
    case "Domain":
      group = FILTER_GROUPS.classifiers;
      break;

    case "Verification":
    case "Submission Score":
      group = FILTER_GROUPS.authors;
      break;

    case "Post type":
    case "Data type":
    case "All data":
    case "Anonymous Posts":
    case "Subreddit":
      group = FILTER_GROUPS.post_attributes;
      break;

    default:
      break;
  }

  return group;
};

export const filters = {
  risk_signals: {
    order: 0.5,
    group: FILTER_GROUPS.classifiers,
    id: "RiskSignalDropdown",
    type: FILTER_TYPES.categorical,
    title: "Risk signals",
    field: "risk_signals",
    requestPropertyGroup: "genericFilters",
    options: [
      {
        label: "Bot-like",
        field: "bot_score",
        value: "Bot-like",
        boolean: true,
      },
      {
        label: "Anomalous",
        field: "bbmi",
        value: "Anomalous",
        boolean: true,
      },
      {
        label: "Toxic content",
        field: "toxicity",
        value: "Toxic",
        boolean: true,
      },
    ],
    getPillText: getCategoricalText,
    tooltip: "",
  },
  harm: {
    order: 1,
    type: FILTER_TYPES.categorical,
    group: FILTER_GROUPS.classifiers,
    title: "Risk",
    field: "harm",
    multi_select: true,
    options: DEFAULT_HARM_OPTIONS,
    class: "harm-slider",
    disabled: false,
    getPillText: (filter, option, value) => {
      const suffix = value.length > 0 ? " " + filter.title : "";
      return getCategoricalText(filter, option, value) + suffix;
    },
  },
  is_harmful: {
    order: 1,
    type: FILTER_TYPES.categorical,
    title: "Risk",
    field: "is_harmful",
    group: FILTER_GROUPS.classifiers,
    options: [
      {
        label: "High Risk content only",
        value: true,
        field: "is_harmful",
        profile: 1,
        boolean: true,
      },
    ],
    disabled: true,
    class: "harm-slider",
    getPillText: (filter) => {
      return filter.title;
    },
  },
  engagement: {
    order: 2,
    type: FILTER_TYPES.min_max,
    title: "Engagements",
    field: "engagement",
    group: FILTER_GROUPS.post_attributes,
    getPillText: getMinMaxText,
    getTooltipText: getMinMaxText,
  },
  likes: {
    order: 3,
    type: FILTER_TYPES.min_max,
    title: "Likes",
    field: "likes",
    group: FILTER_GROUPS.post_attributes,
    getPillText: getMinMaxText,
    getTooltipText: getMinMaxText,
  },
  shares: {
    order: 4,
    type: FILTER_TYPES.min_max,
    group: FILTER_GROUPS.post_attributes,
    title: "Global Shares",
    field: "shares",
    tooltip: "Retweets at the time of data ingestion.",
    getPillText: getMinMaxText,
  },
  user_group_names: {
    order: 4.1,
    multi_select: true,
    type: FILTER_TYPES.categorical,
    title: USER_COLUMN_LABEL + " Groups",
    field: "user_group_names",
    usersFilter: true,
    group: FILTER_GROUPS.authors,
    options: [],
    addBtnLimited: (options) => {
      return (options || []).length >= AUTHOR_GROUPS_LIMIT
        ? `You have reached the maximum of ${AUTHOR_GROUPS_LIMIT} ${USER_COLUMN_LABEL} Groups`
        : "";
    },
    addNew: `Create an ${USER_COLUMN_LABEL} Group`,
    getPillText: getCategoricalText,
  },
  parent_group_names: {
    order: 4.2,
    multi_select: true,
    type: FILTER_TYPES.categorical,
    title: `Parent ${USER_COLUMN_LABEL} Group`,
    field: "parent_group_names",
    usersFilter: true,
    options: [],
    group: FILTER_GROUPS.authors,
    addNew: `Create an ${USER_COLUMN_LABEL} Group`,
    getPillText: (filter, option, value) => {
      const prefix = value.length > 0 ? "Parent: " : "";
      return prefix + getCategoricalText(filter, option, value);
    },
  },
  emotion: {
    order: 6,
    title: "Emotion",
    type: FILTER_TYPES.categorical,
    field: "emotion",
    requestPropertyGroup: "emotionFilters",
    options: EMOTIONS.map((field) => ({
      label: EMOTION_MAPPINGS[field],
      value: EMOTION_MAPPINGS[field],
      field,
      boolean: true,
    })),
    getPillText: getCategoricalText,
    tooltip: "",
    group: FILTER_GROUPS.classifiers,
  },
  sentiment: {
    order: 7,
    title: "Sentiment",
    type: FILTER_TYPES.categorical,
    field: "sentiment",
    requestPropertyGroup: "sentimentFilters",
    options: SENTIMENTS.map((field) => ({
      label: SENTIMENT_MAPPINGS[field],
      value: SENTIMENT_MAPPINGS[field],
      field,
      boolean: true,
    })),
    group: FILTER_GROUPS.classifiers,
    getPillText: getCategoricalText,
    tooltip: "",
  },
  partisanship: {
    order: 9,
    type: FILTER_TYPES.categorical,
    title: "Partisan",
    class: "partisan-slider",
    multi_select: true,
    group: FILTER_GROUPS.classifiers,
    options: [
      {
        label: "Left",
        value: "Left",
        field: "left",
      },
      {
        label: "Center",
        field: "center",
        value: "Center",
      },
      {
        label: "Right",
        value: "Right",
        field: "right",
      },
    ],
    field: "partisanship",
    getPillText: (filter, option, value) => {
      const suffix = value.length > 0 ? " " + filter.title.toLowerCase() : "";
      return getCategoricalText(filter, option, value) + suffix;
    },
  },
  language: {
    order: 11,
    type: FILTER_TYPES.categorical,
    title: "Language",
    field: "language",
    multi_select: true,
    options: [],
    class: "",
    group: FILTER_GROUPS.classifiers,
    getPillText: (filter, option, value) => {
      const selectedOptions = value.map(
        (d) => filter.options.find((x) => x.value === d)?.label
      );
      const firstTwo = selectedOptions.slice(0, 2);
      const rest = selectedOptions.slice(2);

      return firstTwo.join(", ") + (rest.length ? ` +${rest.length}` : "");
    },
  },
};

export const redditFilters = {
  risk_signals: {
    order: 0.5,
    type: FILTER_TYPES.categorical,
    title: "Risk signals",
    field: "risk_signals",
    requestPropertyGroup: "genericFilters",
    group: FILTER_GROUPS.classifiers,
    options: [
      // {
      //   label: "Bot-like",
      //   field: "bot_score",
      //   value: "Bot-like",
      //   boolean: true,
      // },
      {
        label: "Anomalous",
        field: "bbmi",
        value: "Anomalous",
        boolean: true,
      },
      // {
      //   label: "Known hoax",
      //   isBeta: true,
      //   boolean: true,
      //   field: "known_hoax",
      //   value: "Known hoax",
      // },
      {
        label: "Toxic content",
        field: "toxicity",
        value: "Toxic",
        boolean: true,
      },
      // {
      //   label: "Influential",
      //   field: "pagerank",
      //   value: "Influential",
      //   boolean: true,
      // },
    ],
    getPillText: getCategoricalText,
    tooltip: "",
  },
  harm: filters.harm,
  is_harmful: filters.is_harmful,
  engagement: filters.engagement,
  user_group_names: filters.user_group_names,
  parent_group_names: filters.parent_group_names,
  comments: {
    order: 4.1,
    type: FILTER_TYPES.min_max,
    title: "Comments",
    field: "comment_ct",
    group: FILTER_GROUPS.post_attributes,
    getPillText: getMinMaxText,
    getTooltipText: getMinMaxText,
  },
  upvotes: {
    order: 4.2,
    type: FILTER_TYPES.min_max,
    title: "Submission Score",
    field: "reddit_score",
    group: FILTER_GROUPS.authors,
    getPillText: getMinMaxText,
    getTooltipText: getMinMaxText,
  },
  emotion: filters.emotion,
  sentiment: filters.sentiment,
  partisanship: filters.partisanship,
  language: filters.language,
};

export const createDynamicFilter = (dynamicFilter) => {
  if (dynamicFilter.type && dynamicFilter.type) {
    const isCustom = dynamicFilter.options && dynamicFilter.options.length;
    switch (dynamicFilter.type) {
      case FILTER_TYPES.categorical:
        if (isCustom) {
          return {
            tooltip: "",
            getPillText: (filter, options, values) => {
              let retString = "";
              const activeOptions = options.filter((o) =>
                o.inverted
                  ? !values.includes(o.value)
                  : values.includes(o.value)
              );
              for (let i = 0; i < activeOptions.length; i++) {
                const option = activeOptions[i];
                retString += option.pillText;
                if (i < activeOptions.length - 1) {
                  retString += ", ";
                }
              }
              if (!activeOptions || !activeOptions.length) {
                retString += dynamicFilter.title;
              }
              return retString;
            },
            boolean: true,
            options: dynamicFilter.options.map((fo) => {
              return {
                label: fo.display_name,
                field: fo.field,
                value: fo.display_name,
                pillText: fo.pill_text,
                inverted: fo.inverted,
                tooltip: "",
              };
            }),
          };
        }

        return {
          tooltip: "",
          multi_select: true,
          getPillText: getDynamicFilterPillText,
          options: [],
        };
      case FILTER_TYPES.min_max:
        return {
          getPillText: getMinMaxText,
          getTooltipText: getMinMaxText,
        };
      case FILTER_TYPES.boolean:
        return {
          tooltip: "",
          type: FILTER_TYPES.boolean,
          getPillText: getBooleanText,
          options: [],
        };
      case FILTER_TYPES.custom:
        return {
          title: "test",
          type: FILTER_TYPES.categorical,
          boolean: true,
          getPillText: (filter, option, value) => {
            const suffix =
              value.length > 0 ? " " + filter.title.toLowerCase() : "";
            return getCategoricalText(filter, option, value) + suffix;
          },
          options: dynamicFilter.options.map((fo) => {
            return {
              label: fo.display_name,
              field: fo.field,
              value: "Show comments",
              pillText: "Show comments",
              inverted: fo.inverted,
              tooltip: "",
            };
          }),
        };

      default:
        return {};
    }
  }
};

export const adjustFiltersForPlatform = (filtersObject, platform) => {
  const platformConfig = PLATFORM_CONFIG[platform];

  if (!platformConfig.has_engagements) {
    delete filtersObject.engagement;
  }

  if (!platformConfig.has_bot_like && filtersObject.risk_signals) {
    filtersObject.risk_signals.options =
      filtersObject.risk_signals.options.filter((d) => d.field !== "bot_score");
  }

  if (!platformConfig.has_likes) {
    delete filtersObject.likes;
  }

  if (!platformConfig.has_shares) {
    delete filtersObject.shares;
  }

  if (!platformConfig.has_comments) {
    delete filtersObject.comments;
  }

  return filtersObject;
};

// "serialize" is a misnomer
export const serializeFilterObj = (filterObjToBeSerialized) => {
  const serialized = {};
  for (const key in filterObjToBeSerialized) {
    lodashObject.assign(serialized, filterObjToBeSerialized[key].real || {});
  }

  serialized["dynamicFilters"] = getSerializedDynamicFilters(
    filterObjToBeSerialized
  );

  return {
    obj: filterObjToBeSerialized, // TODO there's no point in returning an argument
    serialized,
  };
};

function getSerializedDynamicFilters(filters) {
  let serializedDynamicFilters = undefined;
  const eligibleDynamicFilters = Object.values(filters).filter(
    (fil) =>
      fil.isDynamic &&
      fil.__filter &&
      fil.__filter.elasticSearchField &&
      fil.__filter.boolType
  );

  serializedDynamicFilters = eligibleDynamicFilters.map((df) =>
    createSerializedDynamicFilter(df, df.value)
  );
  return serializedDynamicFilters;
}

const createSerializedDynamicFilter = (filter, value) => {
  let valueArr = [value].flat();

  if (filter.__filter.type === FILTER_TYPES.min_max) {
    valueArr = [
      createSerializedDynamicFilterObj(filter, {
        min: value[0] ? value[0] : 0,
        max: value[1],
      }),
    ];
  } else {
    valueArr = value.map((v) => createSerializedDynamicFilterObj(filter, v));
  }
  return {
    field: filter.__filter.field,
    elasticSearchField: filter.__filter.elasticSearchField,
    boolType: filter.__filter.boolType,
    value: valueArr,
  };
};

function createSerializedDynamicFilterObj(filter, value) {
  let dynamicFilterField = filter.__filter.elasticSearchField;
  if (filter.__filter.isParent) {
    dynamicFilterField += "." + value;
  }

  return {
    elasticSearchField: dynamicFilterField,
    boolType: filter.__filter.boolType,
    isTunable: filter.__filter.isTunable,
    value: value,
  };
}

/**
 * Gets default filters
 * @param {Array} defaultFilters organization.defaultFilters from strapi
 * @param {Array} currentFilters all filters (dynamic + static)
 * @param {String} platform current platform
 * @returns default filters { obj, serialized: { dynamicFilters } }
 */
export const getDefaultFilters = (defaultFilters, currentFilters, platform) => {
  const obj = defaultFilters.reduce((acc, { filter, value, platforms }) => {
    // If no platform matched, skip
    if (!platforms.includes(platform)) {
      return acc;
    }

    const found = currentFilters.find((d) => d.title.includes(filter));

    if (found && found.options) {
      // Find value in options array
      const selectedValue = value
        .map((d) => found.options.find((x) => x.field === d)?.value)
        .filter((d) => d);

      return {
        ...acc,
        ...getFilterObj(found, selectedValue, true),
      };
    }

    return acc;
  }, {});

  return serializeFilterObj(obj);
};

export const harmFilter = filters.harm;
