import { Box, Button, Tooltip } from "@mui/material";

const AnalyzeBtn = ({ onClick }) => {
  const handleClick = () => {
    onClick();
  };

  return (
    <Tooltip title="Analyze" placement="top">
      <Button
        data-testid="analyze-button"
        onClick={handleClick}
        sx={{
          p: 0,
          minWidth: "18px",
          border: "none",
          background: "transparent",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          marginRight: "8px",
          color: "#fff"
        }}
      >
        <Box component="i" className="icon-analyze" fontSize="12px" />
      </Button>
    </Tooltip>
  );
};

export default AnalyzeBtn;
