import { Grid, Typography, Button, Box } from "@mui/material";
import Hint from "./UI/Hint";

export default function WidgetHeader(props) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-around"
      alignItems="center"
      alignContent="center"
      wrap={"nowrap"}
      sx={{
        paddingLeft: props.noPadding ? 0 : "15px",
        paddingRight: props.noPadding ? 0 : "15px",
      }}
    >
      {props.title && (
        <Grid container alignItems="center">
          <Typography
            variant={"h6"}
            sx={{
              fontWeight: 600,
            }}
          >
            {props.title}
          </Typography>

          <Hint tooltipText={props.tooltipTitle} dataTestId={props.title} />
        </Grid>
      )}

      {props.extraContent}

      {props.noButton ? null : (
        <Button
          data-testid={props.id}
          sx={{
            border: "none",
            padding: "5px",
            minWidth: "auto",
            "&:hover": {
              border: "none",
            },
          }}
          variant={"outlined"}
          color={"secondary"}
          onClick={props.onMoreClick}
          id={props.id}
        >
          <Box component="i" className="icon-expand" />
        </Button>
      )}
    </Grid>
  );
}
