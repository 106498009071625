import { useAppContext } from "context/Context";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  adjustFiltersForPlatform,
  createDynamicFilter,
  FILTER_TYPES,
  filters,
  getFilterGroup,
  redditFilters,
} from "./filterConfig";
import {
  getChildKeys,
  getCustomAPI,
  getFieldEntries,
} from "api/endpoints/AnalyticsApi";
import { useParams } from "react-router-dom";
import { useApis } from "api/hooks/useApi";
import {
  DEFAULT_PROFILE,
  LANGUAGES,
  PARENT_USERS,
  PLATFORMS,
  QUERY_KEYS,
} from "utils/constants";
import { cloneDeep } from "lodash";
import { formatElasticSearchLabel } from "utils/format";

// Categorical filters
const getCategoricalFilterFields = (fields) => {
  if (!fields) return [];

  return fields.filter((f) => {
    return (
      f.type === FILTER_TYPES.categorical && !f.isParent && !f.options?.length
    );
  });
};

// Filters with customEndpoint
const getCustomURLFilterFields = (fields) => {
  if (!fields) return [];

  return fields.filter((f) => {
    return f.type && f.customEndpoint && !f.isParent && !f.options?.length;
  });
};

// Parent filters
const getParentFilterFields = (fields) => {
  if (!fields) return [];

  return fields.filter((f) => {
    return f.isParent && !f.customEndpoint && !f.options?.length;
  });
};

const generateFilterRequestBodies = (
  dynamicFilters,
  projectName,
  platform,
  harmClassifier
) => {
  if (!dynamicFilters?.length) {
    return [];
  }

  const categorical = getCategoricalFilterFields(dynamicFilters);
  const customApi = getCustomURLFilterFields(dynamicFilters);
  const parents = getParentFilterFields(dynamicFilters);

  return [
    // Categorical requests
    ...categorical.map((cf) => {
      return {
        ...cf,
        apiKey: QUERY_KEYS.field_entries,
        api: getFieldEntries,
        db: projectName,
        field: cf.elasticSearchField,
        platform: platform,
      };
    }),
    // Custom api: Not used in any of the filtersd.
    // Candidate for deprecation
    ...customApi.map((cf) => {
      return {
        ...cf,
        apiKey: QUERY_KEYS.custom_endpoint,
        api: getCustomAPI,
        db: projectName,
        customEndpoint: cf.customEndpoint,
        platform: platform,
      };
    }),
    // Parent filters
    ...parents.map((cf) => {
      return {
        ...cf,
        apiKey: QUERY_KEYS.group_labels,
        api: getChildKeys,
        db: projectName,
        parent: cf.elasticSearchField,
        platform: platform,
        classifierProfile: harmClassifier,
      };
    }),
  ];
};

export default function useFilters() {
  const { projectName } = useParams();

  const {
    state: {
      platform,
      harmProfile,
      harmClassifier,
      dynamic_filters: dynamicFilters,
      userGroups,
      languages,
      dataLoading,
      unsupportedFeatures,
      modelLabels,
    },
  } = useAppContext();

  const flags = useFlags();
  
  const requests = generateFilterRequestBodies(
    dynamicFilters,
    projectName,
    platform,
    harmClassifier
  );

  const { data: filterOptionsResp, isLoading: filterOptionsLoading } = useApis(
    requests.map(({ apiKey, api, ...payload }) => ({
      apiKey: apiKey,
      apiFn: api,
      payload: payload,
      enabled: Boolean(
        !dataLoading && dynamicFilters?.length && platform && projectName
      ),
    }))
  );

  const getLabel = (filter, filterOption) => {
    if (modelLabels?.[filterOption.elasticSearchField]) {
      return modelLabels[filterOption.elasticSearchField];
    }
    if (filter.field === "ai_narratives") {
      return `narrative-${filterOption.entry}`;
    }
    return filter.isFormatted
      ? formatElasticSearchLabel(filterOption.entry)
      : filterOption.entry;
  };

  const getOptions = (filter) => {
    if (filter.options?.length) {
      return filter.options;
    }

    if (!dynamicFilters?.length) {
      return [];
    }

    if (!filterOptionsResp || filterOptionsLoading) {
      return [];
    }

    const maxOptions = filter.maxOptions;

    if (filter.type === FILTER_TYPES.boolean) {
      return [
        {
          label: filter.title,
          value: filter.elasticSearchField,
          field: filter.elasticSearchField,
          boolean: true,
        },
      ];
    }

    const filterIndex = requests.findIndex((f) => {
      return f.title === filter.title;
    });

    if (filterIndex > -1) {
      let options = filterOptionsResp[filterIndex]
      
      
      if (filter.excludedValues) {
        options = options.filter(
          (option) => filter.excludedValues.indexOf(option.entry) < 0
        );
      }

      if (maxOptions) {
        options = options.slice(0, maxOptions);
      }

      return options.map((fo) => {
        const documentCount = filter.showDocumentCount
          ? fo.documentCount
          : undefined;

        return {
          label: getLabel(filter, fo),
          documentCount,
          value: fo.entry,
          field: fo.entry,
          boolean: true,
        };
      });
    }

    return [];
  };

  const getFilters = () => {
    let filtersObject = cloneDeep(filters);

    if (platform === PLATFORMS.reddit) {
      filtersObject = cloneDeep(redditFilters);
    }

    // adjust filters
    filtersObject = adjustFiltersForPlatform(filtersObject, platform);

    if (dynamicFilters && dynamicFilters.length) {
      dynamicFilters.forEach((ff) => {
        filtersObject[ff.field] = {
          ...ff,
          isDynamic: true,
          ...createDynamicFilter(ff),
          group: getFilterGroup(ff),
        };
      });
    }

    if (flags.multilingual) {
      filtersObject.language.options = languages
        .filter((d) => LANGUAGES[d.name])
        .map((d) => ({
          label: LANGUAGES[d.name].name,
          value: d.name,
          field: d.name,
        }));
    } else {
      delete filtersObject.language;
    }

    if (flags.userGroups) {
      filtersObject.user_group_names.options = userGroups.map((d) => ({
        id: d.id,
        label: d.name,
        value: d.name,
        field: d.name,
        editable: true,
        deletable: true,
        datum: d,
      }));
      filtersObject.parent_group_names.options = userGroups.map((d) => ({
        id: d.id,
        label: d.name,
        value: d.name,
        field: d.name,
        editable: true,
        deletable: true,
        datum: d,
      }));
    } else {
      delete filtersObject.user_group_names;
      delete filtersObject.parent_group_names;
    }

    if (filtersObject.parent_group_names) {
      const parentUserSupported =
        unsupportedFeatures &&
        PARENT_USERS in unsupportedFeatures &&
        unsupportedFeatures[PARENT_USERS];
      filtersObject.parent_group_names.disabled = parentUserSupported;
    }

    if (harmProfile.id !== DEFAULT_PROFILE.id) {
      if (filtersObject.is_harmful) {
        filtersObject.is_harmful.disabled = false;
      }
      filtersObject.harm.disabled = true;
    } else {
      if (filtersObject.is_harmful) {
        filtersObject.is_harmful.disabled = true;
      }
      filtersObject.harm.disabled = false;
    }

    if (!flags.isBlackBirdEmployee) {
      filtersObject.risk_signals.options =
        filtersObject.risk_signals.options.filter(
          (d) => d.field !== "is_known_hoax"
        );
    }

    return Object.values(filtersObject)
      .sort((a, b) => {
        return a.order - b.order;
      })
      .map((filter) => {
        return {
          ...filter,
          options: getOptions(filter),
        };
      });
  };

  return getFilters();
}
